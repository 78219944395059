import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { authSaga } from './saga';
import { ProfileState, LoginData } from './types';
import { User } from '../../../types/User';
import { GeneralDataResponse } from '../../../api/api.types';

const defaultUserData: User = {
    accessUuid: '',
    userId: 0,
    username: '',
    name: 'testing',
    whatsapp: '',
    level: '',
    accessToken: '',
    refreshToken: ''
};

export const initialState: ProfileState = {
    userData: defaultUserData,
    refreshToken: '',
    selectedUserId: '',
    loading: false,
    loginData: null,
    errors: null,
    message: '',
    loginSuccess: false
};

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoginData(state, action: PayloadAction<LoginData>) {
            state.loginData = action.payload;
        },
        setRefreshTokenData(state, action: PayloadAction<string>) {
            state.refreshToken = action.payload;
        },
        loadUserData(state) {
            state.loading = true;
            state.userData = defaultUserData;
        },
        loadRefreshToken(state) {
            state.loading = true;
        },
        setUserData(state, action: PayloadAction<User>) {
            state.loading = false;
            state.userData = action.payload;
        },
        loadRefreshTokenFailed(state, action: PayloadAction<GeneralDataResponse>) {
            state.loading = false;
            state.errors = action.payload.errors;
            state.message = action.payload.message;
        },
        loginSuccess(state, action: PayloadAction<boolean>) {
            state.loginSuccess = action.payload;
        },
        loginFailed(state, action: PayloadAction<GeneralDataResponse>) {
            state.loading = false;
            state.userData = defaultUserData;
            state.errors = action.payload.errors;
            state.message = action.payload.message;
        }
    }
});

export const { actions: profileActions } = slice;

export const useAuthSlice = () => {
    useInjectReducer({ key: slice.name, reducer: slice.reducer });
    useInjectSaga({ key: slice.name, saga: authSaga });
    return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAuthSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
