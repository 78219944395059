import { SetStateAction, useState } from 'react';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../api/api.types';

export default function useToken() {
    const getAccessToken = () => {
        return localStorage.getItem(ACCESS_TOKEN_KEY);
    };

    const getRefreshToken = () => {
        return localStorage.getItem(REFRESH_TOKEN_KEY);
    };

    const [token, setToken] = useState<string | null>(getAccessToken());
    const [refreshToken, setRefreshToken] = useState<string | null>(getRefreshToken());

    const saveToken = (accessToken: SetStateAction<string>, refreshToken: SetStateAction<string>) => {
        if (typeof accessToken === 'string') {
            localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
        }
        if (typeof refreshToken === 'string') {
            localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
        }
        setToken(accessToken);
        setRefreshToken(refreshToken);
    };

    const clearToken = () => {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        localStorage.removeItem(REFRESH_TOKEN_KEY);
        setToken(null);
        setRefreshToken(null);
    };

    return {
        setToken: saveToken,
        clearToken,
        token,
        refreshToken
    };
}
