// @ts-nocheck
import { logger } from '../utils/logger';
import { Api } from './api';

/**
 * Logger
 */
const log = logger().child({ module: 'ApiCustomer' });

/**
 * Manages all requests to the API.
 */
export class ApiFormData {
    private api: Api;

    constructor(api: Api) {
        this.api = api;
    }
}
