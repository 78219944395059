import styled from 'styled-components';

export const Header = styled('header')`
    z-index: 999 !important;
    position: sticky;
    top: 0;
    min-height: 72px;
    background-color: white;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #c2c2c2;
    z-index: 999;
`;

export const LeftSide = styled('div')`
    display: flex;
    align-items: center;
    & > * {
        margin-right: ${({ theme }) => theme.spacing.S2};
    }
`;

export const RightSide = styled('div')`
    display: flex;
    align-items: center;
    & > * {
        margin-left: ${({ theme }) => theme.spacing.S2};
    }
`;
