import React from 'react';
import './StripButton.style.css';
import '../../components/text/Text.style.css';
import { UserEnterpriseType } from '../../types/Dashboard';

interface Props {
    text: string;
    onClick?(): void;
    icon?: any;
    danger?: boolean;
    selected?: UserEnterpriseType;
    smallText?: boolean;
}

export const StripButton: React.SFC<Props> = ({ text, onClick, icon, danger, selected, smallText }) => {
    return (
        <div
            className={selected ? 'strip-button-selected col-container' : 'strip-button col-container'}
            onClick={() => {
                onClick();
            }}
        >
            {icon && <img src={icon} className="recap-icon" />}
            <h2
                className={
                    danger
                        ? 'xl-text-regular danger'
                        : selected
                        ? 'xl-text-regular text-selected'
                        : smallText
                        ? 's-text-regular'
                        : 'xl-text-regular'
                }
            >
                {text}
            </h2>
        </div>
    );
};
