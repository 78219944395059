import { call, put, select, takeLatest } from 'redux-saga/effects';
import { dashboardActions as actions } from './index';
import { logger } from '../../../utils/logger';
import { selectApi } from '../../../api/slice/selectors';
import { selectMonth, selectUserEnterpriseId, selectYear } from './selectors';

/**
 * Logger
 */
const log = logger().child({ module: 'DashboardSaga' });

function* getUserEnterprise() {
    // @ts-ignore
    const api = yield select(selectApi);

    log.info('get Uco Pickup Request');

    try {
        // @ts-ignore
        const response = yield call([api, 'getUserEnterprise']);

        if (response.kind === 'ok') {
            const data = response.data.data;
            yield put(actions.userEnterpriseLoaded(data));
        }
    } catch (err: any) {
        yield put(actions.userEnterpriseError(err));
    }
}

function* getCollectedWaste() {
    const userEnterpriseId: string = yield select(selectUserEnterpriseId);
    const month: string = yield select(selectMonth);
    const year: string = yield select(selectYear);

    // @ts-ignore
    const api = yield select(selectApi);

    log.info('get collected waste');

    try {
        // @ts-ignore
        const response = yield call([api, 'getCollectedWaste'], userEnterpriseId, month, year);

        if (response.kind === 'ok') {
            const data = response.data.data;
            yield put(actions.collectedWasteLoaded(data));
        }
    } catch (err: any) {
        yield put(actions.collectedWasteError(err));
    }
}

function* getTopBusiness() {
    const month: string = yield select(selectMonth);
    const year: string = yield select(selectYear);
    const threshold = 5;

    // @ts-ignore
    const api = yield select(selectApi);

    log.info('get Top Business');

    try {
        // @ts-ignore
        const response = yield call([api, 'getTopBusinessUnit'], month, year, threshold);

        if (response.kind === 'ok') {
            const data = response.data.data.unit;
            yield put(actions.topBusinessUnitLoaded(data));
        }
    } catch (err: any) {
        yield put(actions.topBusinessUnitError(err));
    }
}

function* getTopPartner() {
    const userEnterpriseId: string = yield select(selectUserEnterpriseId);
    const month: string = yield select(selectMonth);
    const year: string = yield select(selectYear);
    const threshold = 5;

    // @ts-ignore
    const api = yield select(selectApi);

    log.info('get Top Business');

    try {
        // @ts-ignore
        const response = yield call([api, 'getTopPartner'], userEnterpriseId, month, year, threshold);

        if (response.kind === 'ok') {
            const data = response.data.data.unit;
            yield put(actions.topPartnerLoaded(data));
        }
    } catch (err: any) {
        yield put(actions.topPartnerError(err));
    }
}

function* getCarbonFootprint() {
    const userEnterpriseId: string = yield select(selectUserEnterpriseId);
    const month: string = yield select(selectMonth);
    const year: string = yield select(selectYear);

    // @ts-ignore
    const api = yield select(selectApi);

    log.info('get carbon footprint');

    try {
        // @ts-ignore
        const response = yield call([api, 'getCarbonFootprint'], userEnterpriseId, month, year);

        if (response.kind === 'ok') {
            const data = response.data.data;
            yield put(actions.carbonFootprintLoaded(data));
        }
    } catch (err: any) {
        yield put(actions.carbonFootprintError(err));
    }
}

function* getExcel() {
    const month: string = yield select(selectMonth);
    const year: string = yield select(selectYear);

    // @ts-ignore
    const api = yield select(selectApi);

    log.info('get excel');

    try {
        // @ts-ignore
        const response = yield call([api, 'getExcel'], month, year);

        if (response.kind === 'ok') {
            const data = response.data.data.url;
            yield put(actions.excelLoaded(data));
        }
    } catch (err: any) {
        yield put(actions.excelError(err));
    }
}

export function* dashboardSaga() {
    yield takeLatest(actions.loadUserEnterprise.type, getUserEnterprise);
    yield takeLatest(actions.loadCollectedWaste.type, getCollectedWaste);
    yield takeLatest(actions.loadTopBusinessUnit.type, getTopBusiness);
    yield takeLatest(actions.loadTopPartner.type, getTopPartner);
    yield takeLatest(actions.loadCarbonFootprint.type, getCarbonFootprint);
    yield takeLatest(actions.loadExcel.type, getExcel);
}
