import React from 'react';
import './TextField.style.css';
import '../../components/text/Text.style.css';

interface Props {
    placeholder?: string;
    type?: 'text' | 'email' | 'number' | 'picker' | 'phone' | 'phone-edit' | 'amount-picker' | 'search-drop-in' | 'disable' | 'password';
    value?: string | undefined;
    onChangeText?: ((text: string) => void) | undefined;
}

export const TextField: React.SFC<Props> = ({ type, placeholder, value, onChangeText }) => {
    return (
        <input
            type={type}
            className="text-field l-text-regular login-card-item"
            placeholder={placeholder}
            value={value}
            onChange={e => onChangeText(e.target.value)}
        />
    );
};
