import React from 'react';
import './Modal.style.css';
// @ts-ignore
import ReactModal from 'react-modal';

interface Props {
    isShowModal: boolean;
    closeModal(): void;
    height: any;
    width: any;
    shouldCloseOnOverlayClick?: boolean;
    shouldCloseOnEsc?: boolean;
    top?: string;
    left?: string;
    transform?: string;
}

export const Modal: React.SFC<Props> = ({
    isShowModal,
    closeModal,
    children,
    height,
    width,
    shouldCloseOnOverlayClick,
    shouldCloseOnEsc,
    top,
    left,
    transform
}) => {
    const overlay = {
        border: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.3)'
    };
    const content = {
        height: height,
        width: width,
        top: top,
        left: left,
        transform: transform,
        border: 'none'
    };

    return (
        <ReactModal
            style={{ content, overlay }}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            shouldCloseOnEsc={shouldCloseOnEsc}
            className={'modal-content'}
            isOpen={isShowModal}
            onRequestClose={closeModal}
        >
            {children}
        </ReactModal>
    );
};
