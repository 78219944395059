import { ApiResponse, ApisauceInstance, create } from 'apisauce';
import { getGeneralApiProblem } from './api-problem';
import { ApiConfig, DEFAULT_API_CONFIG } from './api-config';
import { logger } from '../utils/logger';
import * as Types from './api.types';
import { CARBON_FOOTPRINT, COLLECTED_WASTE_BY_CATEGORY, EXCEL, LOGIN, TOP_BUSINESS_UNIT, TOP_PARTNER, USER_ENTERPRISE } from './api.types';
import { responseMonitor } from './api-monitor';

/**
 * Logger
 */
const log = logger().child({ module: 'MainApi' });

/**
 * Manages all requests to the API.
 */
export class Api {
    /**
     * The underlying apisauce instance which performs the requests.
     */
    // @ts-ignore
    apisauce: ApisauceInstance;

    /**
     * Configurable options.
     */
    config: ApiConfig;

    /**
     * Creates the api.
     *
     * @param config The configuration to use.
     */
    constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
        this.config = DEFAULT_API_CONFIG;
    }

    /**
     * Sets up the API.  This will be called during the bootup
     * sequence and will happen before the first React component
     * is mounted.
     *
     * Be as quick as possible in here.
     */
    setup() {
        log.info('Setup Api');
        // construct the apisauce instance
        this.apisauce = create({
            baseURL: this.config.url,
            timeout: this.config.timeout,
            headers: {
                Accept: 'application/json'
            }
        });
        this.apisauce.addMonitor(responseMonitor);
    }

    setToken(token: string) {
        log.info('TOKEN set 🚀 : ' + token);
        this.apisauce.setHeader('Authorization', `Bearer ${token}`);
    }

    removeToken() {
        log.info('TOKEN removed 🔥');
        this.apisauce.deleteHeader('Authorization');
    }

    // Auth API
    /**
     * Login with username & password
     */
    async login(username: string, password: string): Promise<Types.LoginResult> {
        // make the api call
        const response: ApiResponse<any> = await this.apisauce.post(LOGIN, {
            username,
            password
        });

        // the typical ways to die when calling an api
        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        // transform the data into the format we are expecting
        try {
            return { kind: 'ok', data: response.data.data };
        } catch {
            return { kind: 'bad-data' };
        }
    }

    async getUserEnterprise(): Promise<Types.GetUserEnterpriseResult> {
        // make the api call
        const response: ApiResponse<any> = await this.apisauce.get(USER_ENTERPRISE);
        log.info('API Get User Enterprise', response);

        // the typical ways to die when calling an api
        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        // transform the data into the format we are expecting
        try {
            const data = response.data;
            return { kind: 'ok', data: data };
        } catch {
            return { kind: 'bad-data' };
        }
    }

    async getCollectedWaste(userEnterpriseId: string, month: string, year: string): Promise<Types.GetCollectedWasteResult> {
        // make the api call
        const response: ApiResponse<any> = await this.apisauce.get(COLLECTED_WASTE_BY_CATEGORY, {
            userEnterpriseId,
            year,
            month
        });
        log.info('API Collected Waste', response);

        // the typical ways to die when calling an api
        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        // transform the data into the format we are expecting
        try {
            const data = response.data;
            return { kind: 'ok', data: data };
        } catch {
            return { kind: 'bad-data' };
        }
    }

    async getTopBusinessUnit(month: string, year: string, threshold: number): Promise<Types.GetTopBusinessUnitResult> {
        // make the api call
        const response: ApiResponse<any> = await this.apisauce.get(TOP_BUSINESS_UNIT, {
            year,
            month,
            threshold
        });
        log.info('API Get Top Business Unit', response);

        // the typical ways to die when calling an api
        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        // transform the data into the format we are expecting
        try {
            const data = response.data;
            return { kind: 'ok', data: data };
        } catch {
            return { kind: 'bad-data' };
        }
    }

    async getTopPartner(userEnterpriseId: string, month: string, year: string, threshold: number): Promise<Types.GetTopPartnerResult> {
        // make the api call
        const response: ApiResponse<any> = await this.apisauce.get(TOP_PARTNER, {
            userEnterpriseId,
            year,
            month,
            threshold
        });
        log.info('API Get Top Partner', response);

        // the typical ways to die when calling an api
        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        // transform the data into the format we are expecting
        try {
            const data = response.data;
            return { kind: 'ok', data: data };
        } catch {
            return { kind: 'bad-data' };
        }
    }

    async getCarbonFootprint(userEnterpriseId: string, month: string, year: string): Promise<Types.CarbonFootprintResult> {
        // make the api call
        const response: ApiResponse<any> = await this.apisauce.get(CARBON_FOOTPRINT, {
            userEnterpriseId,
            year,
            month
        });
        log.info('API Carbon Footprint', response);

        // the typical ways to die when calling an api
        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        // transform the data into the format we are expecting
        try {
            const data = response.data;
            return { kind: 'ok', data: data };
        } catch {
            return { kind: 'bad-data' };
        }
    }

    async getExcel(month: string, year: string): Promise<Types.ExcelResult> {
        // make the api call
        const response: ApiResponse<any> = await this.apisauce.get(EXCEL, {
            year,
            month
        });
        log.info('API Get Excel', response);

        // the typical ways to die when calling an api
        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        // transform the data into the format we are expecting
        try {
            const data = response.data;
            return { kind: 'ok', data: data };
        } catch {
            return { kind: 'bad-data' };
        }
    }
}
