import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import useToken from '../hooks/useToken';
import { logger } from '../utils/logger';
import { useDispatch, useSelector } from 'react-redux';
import { useApiSlice } from '../api/slice';
import { useAuthSlice } from '../modules/profile/slice';

const Dashboard = lazy(() => import(/* webpackChunkName: "Dashboard" */ /* webpackPrefetch: true */ '../pages/Dashboard'));
const Login = lazy(() => import(/* webpackChunkName: "Dashboard" */ /* webpackPrefetch: true */ '../pages/Login'));
const BusinessUnit = lazy(() => import(/* webpackChunkName: "Dashboard" */ /* webpackPrefetch: true */ '../pages/BusinessUnit'));

const log = logger().child({ module: 'App' });

export const Routes: React.SFC = () => {
    const { clearToken, token, refreshToken } = useToken();

    const { actionApi } = useApiSlice();
    const { actions } = useAuthSlice();

    const dispatch = useDispatch();

    const logout = () => {
        dispatch(actions.loginSuccess(false));
    };

    useEffect(() => {
        log.info('dispatch token', token);
        dispatch(actionApi.loadToken());
    }, [token]);

    return (
        <Suspense fallback={<span>Loading ...</span>}>
            <BrowserRouter>
                <Switch>
                    <Route
                        exact
                        path={process.env.PUBLIC_URL + '/login'}
                        component={() => (token ? <Redirect to="/business-unit" /> : <Login />)}
                    />
                    <Route
                        exact
                        path={process.env.PUBLIC_URL + '/logout'}
                        component={() => {
                            clearToken();
                            logout();
                            window.location.replace('/login');
                            return <></>;
                        }}
                    />
                    {token ? (
                        <Switch>
                            <Route exact path={process.env.PUBLIC_URL + '/business-unit'} component={() => <BusinessUnit />} />
                            <Route exact path={process.env.PUBLIC_URL + '/:id'} component={() => <Dashboard />} />
                        </Switch>
                    ) : (
                        <Redirect to="/login" />
                    )}
                </Switch>
            </BrowserRouter>
        </Suspense>
    );
};
