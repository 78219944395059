import { WithStyle } from '@medly-components/utils';
import React from 'react';
import * as Styled from './Header.styled';
import { images } from '../../../assets/images';
import './Header.style.css';

interface Props {
    onProfileClick(): void;
}

export const Header: React.SFC<Props> & WithStyle = ({ onProfileClick }) => {
    return (
        <Styled.Header>
            <Styled.LeftSide>
                <img src={images.logoAstra} className="logo-astra" />
                <img src={images.logoReko} className="logo-reko" />
            </Styled.LeftSide>
            <Styled.RightSide>
                <img src={images.accountIcon} className="account-icon" onClick={onProfileClick} />
            </Styled.RightSide>
        </Styled.Header>
    );
};

Header.displayName = 'Header';
Header.Style = Styled.Header;

export default Header;
