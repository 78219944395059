// @ts-nocheck
import { GeneralApiProblem } from './api-problem';
import { CarbonFootprintType, CollectedWasteType, TopBusinessUnitType, UserEnterpriseType } from '../types/Dashboard';

/**
 * Storage Key
 */
export const ACCESS_TOKEN_KEY = 'accessToken';
export const REFRESH_TOKEN_KEY = 'refreshToken';

/**
 * API Definition
 */
const DASHBOARD_PORT = process.env.REACT_APP_DASHBOARD;

// Auth & User
export const LOGIN = DASHBOARD_PORT + '/login';

// Dashboard
export const USER_ENTERPRISE = DASHBOARD_PORT + '/user-enterprise';
export const CARBON_FOOTPRINT = DASHBOARD_PORT + '/carbon-footprint';
export const COLLECTED_WASTE_BY_CATEGORY = DASHBOARD_PORT + '/collected-wasted-by-category';
export const TOP_BUSINESS_UNIT = DASHBOARD_PORT + '/top-active-business-unit';
export const TOP_PARTNER = DASHBOARD_PORT + '/top-active-partner';
export const EXCEL = DASHBOARD_PORT + '/excel';

// Other.. API
// const OTHER_PORT = process.env.REACT_APP_OTHER;
// export const OTHER_URL = OTHER_PORT + '/other';

export interface GeneralDataResponse {
    data: any;
    errors: any;
    message: string | any;
    status: boolean | any;
}

export type GeneralApiResult = { kind: 'ok'; data: GeneralDataResponse } | GeneralApiProblem | undefined;
export type LoginResult = { kind: 'ok'; data: GeneralDataResponse } | GeneralApiProblem;
export type GetCollectedWasteResult = { kind: 'ok'; data: GetCollectedWasteResponse } | GeneralApiProblem;
export type GetUserEnterpriseResult = { kind: 'ok'; data: GetUserEnterpriseResponse } | GeneralApiProblem;
export type GetTopBusinessUnitResult = { kind: 'ok'; data: GetTopBusinessUnitResponse } | GeneralApiProblem;
export type GetTopPartnerResult = { kind: 'ok'; data: GetTopPartnerResponse } | GeneralApiProblem;
export type CarbonFootprintResult = { kind: 'ok'; data: CarbonFootprintResponse } | GeneralApiProblem;
export type ExcelResult = { kind: 'ok'; data: ExcelResponse } | GeneralApiProblem;

export interface GetUserEnterpriseResponse extends GeneralDataResponse {
    data: UserEnterpriseType[];
}

export interface GetCollectedWasteResponse extends GeneralDataResponse {
    data: CollectedWasteType;
}

export interface GetTopBusinessUnitResponse extends GeneralDataResponse {
    data: {
        unit: TopBusinessUnitType[];
    };
}

export interface GetTopPartnerResponse extends GeneralDataResponse {
    data: {
        unit: TopBusinessUnitType[];
    };
}

export interface CarbonFootprintResponse extends GeneralDataResponse {
    data: CarbonFootprintType;
}

export interface ExcelResponse extends GeneralDataResponse {
    data: {
        url: string;
    };
}
