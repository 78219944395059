import { WithStyle } from '@medly-components/utils';
import { CircleLoader } from '@medly-components/loaders';
import React, { useCallback, useState } from 'react';
import * as Styled from './PageContent.styled';
import { Props } from './types';
import { Header } from '../../layout';
import Modal from '../../modal';
import './PageContent.style.css';
import '../../text/Text.style.css';
import StripButton from '../../strip-button';
import { images } from '../../../assets/images';
import { AccountModal } from '../../../pages/Login/AccountModal';
import { useSelector } from 'react-redux';
import { selectUserEnterprise } from '../../../modules/dashboard/slice/selectors';

export const PageContent: React.SFC<Props> & WithStyle = props => {
    const userEnterprise = useSelector(selectUserEnterprise);

    const [isShowModal, setModal] = useState(false);
    const [isShowAccountModal, setAccountModal] = useState(false);
    const [keyword, setKeyword] = useState('');

    // Handle modal
    const showModal = useCallback(() => {
        setModal(true);
    }, []);

    const closeModal = useCallback(() => {
        setModal(false);
    }, []);

    // Handle account modal
    const showAccountModal = useCallback(() => {
        setAccountModal(true);
    }, []);

    const closeAccountModal = useCallback(() => {
        setAccountModal(false);
    }, []);

    return (
        <>
            <Header onProfileClick={showModal} />
            <Styled.PageContent {...props}>
                {props.isLoading && (
                    <div className={'loading'}>
                        <CircleLoader />
                    </div>
                )}
                {props.children}
                <Modal
                    isShowModal={isShowModal}
                    closeModal={closeModal}
                    height={'170px'}
                    width={'320px'}
                    top={'26%'}
                    left={'79%'}
                    transform={'translate(-30%, -50%)'}
                >
                    <div className={'modal-header'}>
                        <h2 className={'l-text-regular'}>Kelola Akun</h2>
                    </div>
                    <StripButton
                        text={'Ganti unit bisnis lain'}
                        onClick={() => {
                            console.log('Change account menu clicked!');
                            closeModal();
                            showAccountModal();
                        }}
                        icon={images.changeAccountIcon}
                    />
                    <StripButton
                        text={'Log Out'}
                        danger={true}
                        onClick={() => {
                            window.location.replace('/logout');
                        }}
                        icon={images.logOutIcon}
                    />
                </Modal>
                <AccountModal
                    data={userEnterprise}
                    isShowModal={isShowAccountModal}
                    closeModal={closeAccountModal}
                    keyword={keyword}
                    setKeyword={setKeyword}
                    shouldCloseOnOverlayClick={true}
                    shouldCloseOnEsc={true}
                />
            </Styled.PageContent>
        </>
    );
};

export const LoginPageContent: React.SFC<Props> & WithStyle = props => {
    return (
        <Styled.LoginPage {...props}>
            {props.isLoading && (
                <div className={'loading'}>
                    <CircleLoader />
                </div>
            )}
            {props.children}
        </Styled.LoginPage>
    );
};

PageContent.displayName = 'PageContent';
PageContent.Style = Styled.PageContent;
