import styled from 'styled-components';

export const PageContent = styled('main')`
    background-color: #fff;
    padding-top: 32px;

    @media (max-width: 1279px) {
        padding-left: 24px;
        padding-right: 24px;
    }

    @media (min-width: 1280px) {
        padding-left: calc(24px + (((100vw - 1280px) / 160) * 16));
        padding-right: calc(24px + (((100vw - 1280px) / 160) * 16));
    }

    @media (min-width: 1440px) {
        padding-left: 40px;
        padding-right: 40px;
    }
`;

export const LoginPage = styled('main')`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #024cce;
    padding-top: 32px;

    @media (max-width: 1279px) {
        padding-left: 24px;
        padding-right: 24px;
    }

    @media (min-width: 1280px) {
        padding-left: calc(24px + (((100vw - 1280px) / 160) * 16));
        padding-right: calc(24px + (((100vw - 1280px) / 160) * 16));
    }

    @media (min-width: 1440px) {
        padding-left: 40px;
        padding-right: 40px;
    }
`;

export const Loader = styled('div')`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 1;
`;
