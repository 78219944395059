import pino from 'pino';

export function logger() {
    if (process.env.REACT_APP_TYPE === 'DEVELOPMENT') {
        return pino({
            level: 'debug'
        });
    }
    return pino({
        level: 'silent'
    });
}
