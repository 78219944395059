import React, { useEffect, useState } from 'react';
import './Login.style.css';
import '../../components/text/Text.style.css';
import TextField from '../../components/text-field';
import StripButton from '../../components/strip-button';
import Modal from '../../components/modal';
import { UserEnterpriseType } from '../../types/Dashboard';

interface Props {
    data: UserEnterpriseType[];
    isShowModal: boolean;
    closeModal(): void;
    keyword: string;
    setKeyword(e: string): void;
    shouldCloseOnEsc: boolean;
    shouldCloseOnOverlayClick: boolean;
}

export const AccountModal: React.SFC<Props> = ({
    data,
    isShowModal,
    closeModal,
    keyword,
    setKeyword,
    shouldCloseOnEsc,
    shouldCloseOnOverlayClick
}) => {
    const [showData, setShowData] = useState<UserEnterpriseType[]>(data);

    const onSelect = async (item: UserEnterpriseType) => {
        window.location.replace('/' + item.id);
    };

    const inputHandler = (e: string) => {
        const lowerCase = e.toLowerCase();
        setKeyword(lowerCase);
    };

    const filteredData = data.filter(el => {
        return el.businessUnit.toLowerCase().includes(keyword);
    });

    useEffect(() => {
        if (keyword !== '') {
            setShowData(filteredData);
        } else {
            setShowData(data);
        }
    }, [keyword]);

    useEffect(() => {
        setShowData(data);
    }, [data]);

    return (
        <Modal
            isShowModal={isShowModal}
            closeModal={closeModal}
            height={'432px'}
            width={'320px'}
            shouldCloseOnEsc={shouldCloseOnEsc}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            top={'50%'}
            left={'50%'}
            transform={'translate(-50%, -50%)'}
        >
            <div className={'modal-header'}>
                <h2 className={'l-text-regular'}>Pilih Unit Bisnis</h2>
                <TextField
                    value={keyword}
                    placeholder={'Cari Unit Bisnis'}
                    type={'text'}
                    onChangeText={e => {
                        inputHandler(e);
                    }}
                />
            </div>
            <div
                style={{
                    position: 'relative',
                    height: '250px',
                    overflow: 'scroll'
                }}
                className={'scroll-container'}
            >
                {showData.map((item, key) => {
                    return (
                        <StripButton
                            key={key}
                            text={item.businessUnit}
                            onClick={async () => {
                                await onSelect(item);
                            }}
                        />
                    );
                })}
            </div>
            <button className={'button-modal l-text-semibold'}>Lanjutkan</button>
        </Modal>
    );
};
