import { call, put, select, takeLatest } from 'redux-saga/effects';
import { profileActions as actions } from './index';
import { LoginData } from './types';
import { selectLoginData, selectRefreshToken } from './selectors';
import { selectApi } from '../../../api/slice/selectors';
import { apiActions } from '../../../api/slice';
import { logger } from '../../../utils/logger';

/**
 * Logger
 */
const log = logger().child({ module: 'MainApi' });

export function* loginFlow() {
    const body: LoginData = yield select(selectLoginData);
    // @ts-ignore
    const api = yield select(selectApi);

    if (body.username?.length === 0 || body.username === '') {
        // TODO ERROR MESSAGE ACTIONS
        log.error('username / password is empty');
        return;
    }

    try {
        // @ts-ignore
        const response = yield call([api, 'login'], body.username, body.password);

        if (response.kind === 'ok') {
            const data = response.data;

            yield put(actions.setUserData(data));
            yield put(actions.loginSuccess(true));
            yield put(
                apiActions.setApiToken({
                    accessToken: data.accessToken,
                    refreshToken: data.refreshToken
                })
            );
            yield put(
                apiActions.saveToken({
                    accessToken: data.accessToken,
                    refreshToken: data.refreshToken
                })
            );
        } else {
            yield put(
                actions.loginFailed({
                    errors: response.response?.errors,
                    data: response.response?.data,
                    message: response.response?.message,
                    status: response.response?.status
                })
            );
        }
    } catch (err: any) {
        log.error(err);
    }
}

export function* refreshTokenFlow() {
    const refreshToken: string = yield select(selectRefreshToken);
    // @ts-ignore
    const api = yield select(selectApi);

    try {
        // @ts-ignore
        const response = yield call([api, 'requestNewToken'], refreshToken);

        if (response.kind === 'ok') {
            const data = response.data;
            yield put(
                apiActions.setApiToken({
                    accessToken: data.accessToken,
                    refreshToken: data.refreshToken
                })
            );
            yield put(
                apiActions.saveToken({
                    accessToken: data.accessToken,
                    refreshToken: data.refreshToken
                })
            );
        } else {
            yield put(
                actions.loadRefreshTokenFailed({
                    errors: response.response?.errors,
                    data: response.response?.data,
                    message: response.response?.message,
                    status: response.response?.status
                })
            );
        }
    } catch (err: any) {
        log.error(err);
    }
}

export function* authSaga() {
    yield takeLatest(actions.loadRefreshToken.type, refreshTokenFlow);
    yield takeLatest(actions.loadUserData.type, loginFlow);
}
