import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../utils/@reduxjs/toolkit';
import { useInjectReducer } from '../../utils/redux-injectors';
import { ApiState } from './types';
import { Api } from '../api';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../api.types';
import { logger } from '../../utils/logger';
import { ApiFormData } from '../api-form-data';

const apiInstance = new Api();
apiInstance.setup();

const apiFormData = new ApiFormData(apiInstance);

export const initialState: ApiState = {
    api: apiInstance,
    apiFormData: apiFormData,
    accessToken: null,
    refreshToken: null
};

/**
 * Logger
 */
const log = logger().child({ module: 'ApiSliceIndex' });

const slice = createSlice({
    name: 'api',
    initialState,
    reducers: {
        setApiToken(state, action: PayloadAction<{ accessToken: string; refreshToken: string }>) {
            state.api.setToken(action.payload.accessToken);

            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
        },
        saveToken(state, action: PayloadAction<{ accessToken: string; refreshToken: string }>) {
            localStorage.setItem(ACCESS_TOKEN_KEY, action.payload.accessToken);
            localStorage.setItem(REFRESH_TOKEN_KEY, action.payload.refreshToken);
        },
        clearToken(state) {
            localStorage.removeItem(ACCESS_TOKEN_KEY);
            localStorage.removeItem(REFRESH_TOKEN_KEY);

            state.api.setToken('');
            state.accessToken = '';
            state.refreshToken = '';
        },
        loadToken(state) {
            const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY) ?? '';
            const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY) ?? '';

            state.api.setToken(accessToken);
            state.accessToken = accessToken;
            state.refreshToken = refreshToken;
        }
    }
});

export const { actions: apiActions } = slice;

export const useApiSlice = () => {
    useInjectReducer({ key: slice.name, reducer: slice.reducer });
    return { actionApi: slice.actions };
};
