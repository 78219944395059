export const images = {
    logoAstra: require('./logoAstra.png'),
    logoReko: require('./logoReko.png'),
    accountIcon: require('./accountIcon.png'),
    downloadIcon: require('./downloadIcon.png'),
    truckIcon: require('./truckIcon.png'),
    amountIcon: require('./amountIcon.png'),
    graphIcon: require('./graphIcon.png'),
    trashIcon: require('./trashIcon.png'),
    changeAccountIcon: require('./changeAccountIcon.png'),
    logOutIcon: require('./logOutIcon.png'),
    infoIcon: require('./infoIcon.png'),
    infoIconActive: require('./infoIconActive.png'),
    treePlantedBackground: require('./treePlantedBackground.png'),
    bulbBackground: require('./bulbBackground.png'),
    coalBurnedBackground: require('./coalBurnedBackground.png'),
    dieselBackground: require('./dieselBackground.png'),
    distanceDrivenBackground: require('./distanceDrivenBackground.png'),
    petrolBackground: require('./petrolBackground.png'),
    trashBagBackground: require('./trashBagBackground.png')
};
