import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './index';

const selectSlice = (state: RootState) => state.dashboard || initialState;

export const selectDashboardLoading = createSelector([selectSlice], state => state.loading);
export const selectUserEnterprise = createSelector([selectSlice], state => state.userEnterprise);
export const selectSelectedUserEnterprise = createSelector([selectSlice], state => state.selectedUserEnterprise);
export const selectUserEnterpriseId = createSelector([selectSlice], state => state.userEnterpriseId);
export const selectMonth = createSelector([selectSlice], state => state.month);
export const selectYear = createSelector([selectSlice], state => state.year);
export const selectCollectedWaste = createSelector([selectSlice], state => state.collectedWaste);
export const selectTopBusinessUnit = createSelector([selectSlice], state => state.topBusinessUnit);
export const selectTopPartner = createSelector([selectSlice], state => state.topPartner);
export const selectCarbonFootprint = createSelector([selectSlice], state => state.carbonFootprint);
export const selectExcel = createSelector([selectSlice], state => state.excel);
