import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../types';
import { initialState } from './index';

const selectSlice = (state: RootState) => state.api || initialState;

export const selectApi = createSelector([selectSlice], state => state.api);

export const selectAllApi = createSelector([selectSlice], state => state);
