import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { dashboardSaga } from './saga';
import { DashboardState } from './types';
import { CarbonFootprintType, CollectedWasteType, TopBusinessUnitType, UserEnterpriseType } from '../../../types/Dashboard';

export const initialState: DashboardState = {
    userEnterprise: [],
    userEnterpriseId: '',
    selectedUserEnterprise: undefined,
    collectedWaste: undefined,
    topPartner: [],
    topBusinessUnit: [],
    carbonFootprint: undefined,
    excel: '',
    month: '',
    year: '',
    threshold: 5,
    error: null,
    loading: false
};

const slice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        loadUserEnterprise(state) {
            state.loading = true;
            state.error = null;
            state.userEnterprise = [];
        },
        userEnterpriseLoaded(state, action: PayloadAction<UserEnterpriseType[]>) {
            state.loading = false;
            state.error = null;
            state.userEnterprise = action.payload;
        },
        userEnterpriseError(state, action: PayloadAction<any>) {
            state.loading = false;
            state.error = action.payload.error;
            state.userEnterprise = [];
        },

        setSelectedUserEnterprise(state, action: PayloadAction<UserEnterpriseType>) {
            state.selectedUserEnterprise = action.payload;
        },

        loadTopBusinessUnit(
            state,
            action: PayloadAction<{
                month: string;
                year: string;
            }>
        ) {
            state.loading = true;
            state.error = null;
            state.topBusinessUnit = [];
            state.month = action.payload.month;
            state.year = action.payload.year;
        },
        topBusinessUnitLoaded(state, action: PayloadAction<TopBusinessUnitType[]>) {
            state.loading = false;
            state.error = null;
            state.topBusinessUnit = action.payload;
        },
        topBusinessUnitError(state, action: PayloadAction<any>) {
            state.loading = false;
            state.error = action.payload.error;
            state.topBusinessUnit = [];
        },

        loadTopPartner(
            state,
            action: PayloadAction<{
                userEnterpriseId: string;
                month: string;
                year: string;
            }>
        ) {
            state.loading = true;
            state.error = null;
            state.topPartner = [];
            state.userEnterpriseId = action.payload.userEnterpriseId;
            state.month = action.payload.month;
            state.year = action.payload.year;
        },
        topPartnerLoaded(state, action: PayloadAction<TopBusinessUnitType[]>) {
            state.loading = false;
            state.error = null;
            state.topPartner = action.payload;
        },
        topPartnerError(state, action: PayloadAction<any>) {
            state.loading = false;
            state.error = action.payload.error;
            state.topPartner = [];
        },

        loadCollectedWaste(
            state,
            action: PayloadAction<{
                userEnterpriseId: string;
                month: string;
                year: string;
            }>
        ) {
            state.loading = true;
            state.error = null;
            state.collectedWaste = undefined;
            state.userEnterpriseId = action.payload.userEnterpriseId;
            state.month = action.payload.month;
            state.year = action.payload.year;
        },
        collectedWasteLoaded(state, action: PayloadAction<CollectedWasteType>) {
            state.loading = false;
            state.error = null;
            state.collectedWaste = action.payload;
        },
        collectedWasteError(state, action: PayloadAction<any>) {
            state.loading = false;
            state.error = action.payload.error;
            state.collectedWaste = undefined;
        },

        loadCarbonFootprint(
            state,
            action: PayloadAction<{
                userEnterpriseId: string;
                month: string;
                year: string;
            }>
        ) {
            state.loading = true;
            state.error = null;
            state.carbonFootprint = undefined;
            state.userEnterpriseId = action.payload.userEnterpriseId;
            state.month = action.payload.month;
            state.year = action.payload.year;
        },
        carbonFootprintLoaded(state, action: PayloadAction<CarbonFootprintType>) {
            state.loading = false;
            state.error = null;
            state.carbonFootprint = action.payload;
        },
        carbonFootprintError(state, action: PayloadAction<any>) {
            state.loading = false;
            state.error = action.payload.error;
            state.carbonFootprint = undefined;
        },

        loadExcel(
            state,
            action: PayloadAction<{
                month: string;
                year: string;
            }>
        ) {
            state.loading = true;
            state.error = null;
            state.excel = '';
            state.month = action.payload.month;
            state.year = action.payload.year;
        },
        excelLoaded(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = null;
            state.excel = action.payload;
        },
        excelError(state, action: PayloadAction<any>) {
            state.loading = false;
            state.error = action.payload.error;
            state.excel = '';
        }
    }
});

export const { actions: dashboardActions } = slice;

export const useDashboardSlice = () => {
    useInjectReducer({ key: slice.name, reducer: slice.reducer });
    useInjectSaga({ key: slice.name, saga: dashboardSaga });
    return { actionsDashboard: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAuthSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
